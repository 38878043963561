import React, { useState } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { TrendingMarkets } from './TrendingMarkets';
import { Categories } from './Categories';
import { MarketGrid } from './MarketGrid';
import { Activities } from './Activities';
import { TokenInfo } from './TokenInfo';
import { VotingModal } from './VotingModal';

function App() {
    const [isVotingModalOpen, setVotingModalOpen] = useState(false);
    const [selectedMarket, setSelectedMarket] = useState(null);

    return (
        <div className="min-h-screen bg-[#0D0D0D] text-white">
            <Header />

            <main className="container mx-auto px-4 py-6 space-y-8">
                <TrendingMarkets />
                <Categories />
                <MarketGrid
                    onVoteClick={(market) => {
                        setSelectedMarket(market);
                        setVotingModalOpen(true);
                    }}
                />
                <Activities />
                <TokenInfo />
            </main>

            <Footer />

            <VotingModal
                isOpen={isVotingModalOpen}
                onClose={() => setVotingModalOpen(false)}
                market={selectedMarket}
            />
        </div>
    );
}

export default App;