import React, { useState } from 'react';
import { X, AlertCircle } from 'lucide-react';

export function VotingModal({ isOpen, onClose, market }) {
    const [amount, setAmount] = useState('');
    const potentialWin = parseFloat(amount) * 1.8; // Example multiplier

    if (!isOpen || !market) return null;

    return (
        <div className="fixed inset-0 bg-black/80 flex items-center justify-center p-4 z-50">
            <div className="bg-[#0D0D0D] border border-yellow-500/20 rounded-lg w-full max-w-lg">
                <div className="flex items-center justify-between p-4 border-b border-yellow-500/20">
                    <h3 className="text-lg font-bold">Place Your Prediction</h3>
                    <button onClick={onClose} className="text-gray-400 hover:text-white">
                        <X className="w-6 h-6" />
                    </button>
                </div>

                <div className="p-6 space-y-6">
                    <img
                        src={market.image}
                        alt={market.title}
                        className="w-full h-48 object-cover rounded-lg"
                    />

                    <div>
                        <h4 className="font-semibold mb-2">{market.title}</h4>
                        <div className="flex items-center text-sm text-gray-400">
                            <AlertCircle className="w-4 h-4 mr-2" />
                            Voting ends in {new Date(market.endDate).toLocaleDateString()}
                        </div>
                    </div>

                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-400 mb-2">
                                Amount (WODS)
                            </label>
                            <input
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                className="w-full bg-black border border-yellow-500/20 rounded-lg px-4 py-2 focus:outline-none focus:border-yellow-500"
                                placeholder="Enter amount..."
                            />
                        </div>

                        <div className="bg-yellow-500/10 rounded-lg p-4">
                            <div className="flex justify-between text-sm mb-2">
                                <span>Voting for</span>
                                <span className={market.vote === 'yes' ? 'text-green-400' : 'text-red-400'}>
                                    {market.vote.toUpperCase()}
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <span>Potential win</span>
                                <span className="text-yellow-500 font-semibold">
                                    {potentialWin.toFixed(2)} WODS
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-4 border-t border-yellow-500/20">
                    <button
                        onClick={onClose}
                        className="w-full px-6 py-3 bg-yellow-500 text-black font-semibold rounded-lg hover:bg-yellow-400 transition-colors"
                    >
                        Confirm Prediction
                    </button>
                </div>
            </div>
        </div>
    );
}