import React from "react";
import { TrendingUp, Flame, ArrowUpRight, ArrowDownRight } from "lucide-react";

const trendingMarkets = [
    {
        id: 1,
        title: "Will BTC hit 100k in 2024?",
        volume: "234.5K",
        yesPercentage: 78,
        change: "+12.3%",
        isHot: true,
        endingSoon: true,
    },
    {
        id: 2,
        title: "ETH to flip BTC by EOY?",
        volume: "189.2K",
        yesPercentage: 32,
        change: "-5.8%",
        isHot: true,
        endingSoon: false,
    },
    {
        id: 3,
        title: "DOGE to $1?",
        volume: "156.7K",
        yesPercentage: 45,
        change: "+8.9%",
        isHot: false,
        endingSoon: true,
    },
    {
        id: 4,
        title: "Solana to $500?",
        volume: "143.2K",
        yesPercentage: 67,
        change: "+15.2%",
        isHot: true,
        endingSoon: false,
    },
    {
        id: 5,
        title: "New Bitcoin ATH in March?",
        volume: "132.8K",
        yesPercentage: 82,
        change: "+3.4%",
        isHot: false,
        endingSoon: true,
    },
    {
        id: 6,
        title: "XRP to win SEC case?",
        volume: "128.4K",
        yesPercentage: 71,
        change: "-2.1%",
        isHot: true,
        endingSoon: false,
    },
];

export const TrendingMarkets = () => {
    return (
        <section className="py-6">
            {/* Header */}
            <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                    <TrendingUp className="w-5 h-5 text-yellow-500 mr-2" />
                    <h2 className="text-xl font-bold">Trending Markets</h2>
                </div>
                <button className="text-sm text-yellow-500 hover:text-yellow-400">
                    View All
                </button>
            </div>

            {/* Markets Grid */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {trendingMarkets.map((market) => (
                    <div
                        key={market.id}
                        className="bg-black border border-yellow-500/20 rounded-lg p-4 hover:border-yellow-500/40 transition-colors"
                    >
                        {/* Market Title and Tags */}
                        <div className="flex items-start justify-between mb-2">
                            <h3 className="font-semibold">{market.title}</h3>
                            <div className="flex items-center space-x-2">
                                {market.isHot && (
                                    <span className="flex items-center text-orange-500">
                                        <Flame className="w-4 h-4" />
                                    </span>
                                )}
                                {market.endingSoon && (
                                    <span className="text-xs px-2 py-1 bg-red-500/20 text-red-400 rounded">
                                        Ending Soon
                                    </span>
                                )}
                            </div>
                        </div>

                        {/* Market Stats */}
                        <div className="flex justify-between items-center mb-3">
                            <span className="text-sm text-gray-400">Vol: ${market.volume}</span>
                            <span
                                className={`text-sm flex items-center ${market.change.startsWith("+")
                                        ? "text-green-400"
                                        : "text-red-400"
                                    }`}
                            >
                                {market.change.startsWith("+") ? (
                                    <ArrowUpRight className="w-4 h-4 mr-1" />
                                ) : (
                                    <ArrowDownRight className="w-4 h-4 mr-1" />
                                )}
                                {market.change}
                            </span>
                        </div>

                        {/* Yes Percentage Bar */}
                        <div className="flex items-center">
                            <div className="flex-1">
                                <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
                                    <div
                                        className="h-full bg-yellow-500"
                                        style={{ width: `${market.yesPercentage}%` }}
                                    />
                                </div>
                            </div>
                            <span className="ml-3 text-sm font-medium">
                                {market.yesPercentage}% YES
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};