import React from 'react';
import { Clock, DollarSign } from 'lucide-react';

const markets = [
    {
        id: 1,
        title: "Will Solana flip Ethereum in 2024?",
        category: "Crypto",
        image: "https://images.unsplash.com/photo-1639762681485-074b7f938ba0?auto=format&fit=crop&q=80&w=800",
        volume: "432.1K",
        yesPercentage: 65,
        endDate: "2024-12-31",
        totalVotes: 1234,
    },
    {
        id: 2,
        title: "Tesla to accept DOGE payments?",
        category: "Technology",
        image: "https://images.unsplash.com/photo-1601158935942-52255782d322?auto=format&fit=crop&q=80&w=800",
        volume: "256.8K",
        yesPercentage: 42,
        endDate: "2024-06-30",
        totalVotes: 892,
    },
    {
        id: 3,
        title: "BTC to reach 100K by EOY?",
        category: "Crypto",
        image: "https://images.unsplash.com/photo-1518546305927-5a555bb7020d?auto=format&fit=crop&q=80&w=800",
        volume: "789.3K",
        yesPercentage: 78,
        endDate: "2024-12-31",
        totalVotes: 2341,
    },
    {
        id: 4,
        title: "Apple to launch AR glasses?",
        category: "Technology",
        image: "https://images.unsplash.com/photo-1617170788899-5435d50b4f42?auto=format&fit=crop&q=80&w=800",
        volume: "167.5K",
        yesPercentage: 55,
        endDate: "2024-09-30",
        totalVotes: 743,
    },
    {
        id: 5,
        title: "ETH to implement new scaling?",
        category: "Crypto",
        image: "https://images.unsplash.com/photo-1622630998477-20aa696ecb05?auto=format&fit=crop&q=80&w=800",
        volume: "345.2K",
        yesPercentage: 82,
        endDate: "2024-08-15",
        totalVotes: 1567,
    },
    {
        id: 6,
        title: "New memecoin to 100x?",
        category: "Crypto",
        image: "https://images.unsplash.com/photo-1621761191319-c6fb62004040?auto=format&fit=crop&q=80&w=800",
        volume: "123.4K",
        yesPercentage: 35,
        endDate: "2024-05-01",
        totalVotes: 678,
    },
    {
        id: 7,
        title: "ChatGPT to pass bar exam?",
        category: "Technology",
        image: "https://images.unsplash.com/photo-1677442136019-21780ecad995?auto=format&fit=crop&q=80&w=800",
        volume: "234.5K",
        yesPercentage: 68,
        endDate: "2024-07-31",
        totalVotes: 912,
    },
    {
        id: 8,
        title: "NVIDIA to launch AI chip?",
        category: "Technology",
        image: "https://images.unsplash.com/photo-1555949963-aa79dcee981c?auto=format&fit=crop&q=80&w=800",
        volume: "445.6K",
        yesPercentage: 91,
        endDate: "2024-06-15",
        totalVotes: 1876,
    },
    {
        id: 9,
        title: "New L1 chain to launch?",
        category: "Crypto",
        image: "https://images.unsplash.com/photo-1639762681485-074b7f938ba0?auto=format&fit=crop&q=80&w=800",
        volume: "178.9K",
        yesPercentage: 45,
        endDate: "2024-04-30",
        totalVotes: 534,
    },
];

export function MarketGrid({ onVoteClick }) {
    return (
        <section className="py-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {markets.map((market) => (
                    <div
                        key={market.id}
                        className="bg-black border border-yellow-500/20 rounded-lg overflow-hidden hover:border-yellow-500/40 transition-colors"
                    >
                        <img
                            src={market.image}
                            alt={market.title}
                            className="w-full h-48 object-cover"
                        />
                        <div className="p-4">
                            <div className="flex items-center mb-2">
                                <span className="text-xs px-2 py-1 bg-yellow-500/10 text-yellow-500 rounded">
                                    {market.category}
                                </span>
                            </div>
                            <h3 className="font-semibold mb-3">{market.title}</h3>

                            <div className="space-y-3">
                                <div className="flex items-center justify-between text-sm">
                                    <span className="text-gray-400">Volume</span>
                                    <span className="flex items-center">
                                        <DollarSign className="w-4 h-4 mr-1" />
                                        {market.volume}
                                    </span>
                                </div>

                                <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
                                    <div
                                        className="h-full bg-yellow-500"
                                        style={{ width: `${market.yesPercentage}%` }}
                                    />
                                </div>

                                <div className="flex items-center justify-between text-sm">
                                    <span>YES {market.yesPercentage}%</span>
                                    <span>NO {100 - market.yesPercentage}%</span>
                                </div>

                                <div className="flex items-center justify-between text-sm text-gray-400">
                                    <span className="flex items-center">
                                        <Clock className="w-4 h-4 mr-1" />
                                        Ends {new Date(market.endDate).toLocaleDateString()}
                                    </span>
                                    <span>{market.totalVotes} votes</span>
                                </div>

                                <div className="grid grid-cols-2 gap-2">
                                    <button
                                        onClick={() => onVoteClick({ ...market, vote: 'yes' })}
                                        className="px-4 py-2 bg-green-500/20 text-green-400 rounded-md hover:bg-green-500/30 transition-colors"
                                    >
                                        YES
                                    </button>
                                    <button
                                        onClick={() => onVoteClick({ ...market, vote: 'no' })}
                                        className="px-4 py-2 bg-red-500/20 text-red-400 rounded-md hover:bg-red-500/30 transition-colors"
                                    >
                                        NO
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="flex justify-center mt-8">
                <button className="px-6 py-2 bg-yellow-500/20 text-yellow-500 rounded-md hover:bg-yellow-500/30 transition-colors">
                    Load More Markets
                </button>
            </div>
        </section>
    );
}