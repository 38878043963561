import React from "react";
import { Rocket, BarChart2 } from "lucide-react";

export const TokenInfo = () => {
    return (
        <section className="py-6">
            <div className="bg-black border border-yellow-500/20 rounded-lg p-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {/* Token Overview */}
                    <div className="space-y-2">
                        <h3 className="text-xl font-bold text-yellow-500">$WODS Token</h3>
                        <p className="text-gray-400">The native token of The Dumb Street</p>
                        <div className="flex space-x-3">
                            <button className="flex items-center px-4 py-2 bg-yellow-500 text-black font-semibold rounded-md hover:bg-yellow-400 transition-colors">
                                <Rocket className="w-4 h-4 mr-2" />
                                Join the Pack
                            </button>
                            <button className="flex items-center px-4 py-2 bg-yellow-500/20 text-yellow-500 font-semibold rounded-md hover:bg-yellow-500/30 transition-colors">
                                <BarChart2 className="w-4 h-4 mr-2" />
                                View Chart
                            </button>
                        </div>
                    </div>

                    {/* Token Stats */}
                    <div className="grid grid-cols-2 gap-4 md:col-span-2">
                        <div className="bg-yellow-500/10 rounded-lg p-4">
                            <div className="text-sm text-gray-400">Market Cap</div>
                            <div className="text-xl font-bold">$4.2M</div>
                        </div>
                        <div className="bg-yellow-500/10 rounded-lg p-4">
                            <div className="text-sm text-gray-400">24h Volume</div>
                            <div className="text-xl font-bold">$890K</div>
                        </div>
                        <div className="bg-yellow-500/10 rounded-lg p-4">
                            <div className="text-sm text-gray-400">Holders</div>
                            <div className="text-xl font-bold">2,345</div>
                        </div>
                        <div className="bg-yellow-500/10 rounded-lg p-4">
                            <div className="text-sm text-gray-400">Total Supply</div>
                            <div className="text-xl font-bold">10M WODS</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};