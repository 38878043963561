import React from 'react';
import { Activity, PlusCircle } from 'lucide-react';

export function Activities() {
    return (
        <section className="py-6 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-black border border-yellow-500/20 rounded-lg p-4">
                <div className="flex items-center mb-4">
                    <Activity className="w-5 h-5 text-yellow-500 mr-2" />
                    <h2 className="text-lg font-bold">Recent Votes</h2>
                </div>

                <div className="space-y-4">
                    {[1, 2, 3, 4, 5].map((i) => (
                        <div key={i} className="flex items-center justify-between">
                            <div className="flex items-center">
                                <img
                                    src={`https://api.dicebear.com/7.x/pixel-art/svg?seed=${i}`}
                                    alt="avatar"
                                    className="w-8 h-8 rounded-full"
                                />
                                <div className="ml-3">
                                    <div className="text-sm">0x1234...5678</div>
                                    <div className="text-xs text-gray-400">
                                        Voted YES on "Will BTC hit 100k?"
                                    </div>
                                </div>
                            </div>
                            <span className="text-sm text-yellow-500">2.5 WODS</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className="bg-black border border-yellow-500/20 rounded-lg p-4">
                <div className="flex items-center mb-4">
                    <PlusCircle className="w-5 h-5 text-yellow-500 mr-2" />
                    <h2 className="text-lg font-bold">New Markets</h2>
                </div>

                <div className="space-y-4">
                    {[1, 2, 3, 4, 5].map((i) => (
                        <div key={i} className="flex items-center justify-between">
                            <div className="flex items-center">
                                <div className="w-8 h-8 bg-yellow-500/20 rounded-lg flex items-center justify-center">
                                    <span className="text-yellow-500 text-sm">#{i}</span>
                                </div>
                                <div className="ml-3">
                                    <div className="text-sm">Will ETH 2.0 launch in Q2?</div>
                                    <div className="text-xs text-gray-400">Created 2h ago</div>
                                </div>
                            </div>
                            <span className="text-sm text-gray-400">Vol: $12.3K</span>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}