import React, { useState } from 'react';
import { Wallet, Twitter, MessageCircle, Menu, User } from 'lucide-react';
import { ProfileModal } from './ProfileModal';

export function Header() {
    const [isProfileOpen, setProfileOpen] = useState(false);

    return (
        <>
            <header className="bg-black border-b border-yellow-500/20">
                <div className="container mx-auto px-4">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center space-x-4">
                            <div className="text-yellow-500 font-bold text-xl">THE DUMB STREET</div>
                            <div className="hidden md:flex items-center px-3 py-1 bg-yellow-500/10 rounded-md">
                                <span className="text-yellow-500">$WODS:</span>
                                <span className="ml-2">$0.42</span>
                                <span className="ml-2 text-green-400">+5.2%</span>
                            </div>
                        </div>

                        <div className="flex items-center space-x-4">
                            <button
                                onClick={() => setProfileOpen(true)}
                                className="text-yellow-500 hover:text-yellow-400"
                            >
                                <User className="w-5 h-5" />
                            </button>
                            <a href="#" className="text-yellow-500 hover:text-yellow-400">
                                <Twitter className="w-5 h-5" />
                            </a>
                            <a href="#" className="text-yellow-500 hover:text-yellow-400">
                                <MessageCircle className="w-5 h-5" />
                            </a>
                            <button className="hidden md:flex items-center px-4 py-2 bg-yellow-500 text-black font-semibold rounded-md hover:bg-yellow-400 transition-colors">
                                <Wallet className="w-4 h-4 mr-2" />
                                Connect Wallet
                            </button>
                            <button className="md:hidden">
                                <Menu className="w-6 h-6 text-yellow-500" />
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            <ProfileModal
                isOpen={isProfileOpen}
                onClose={() => setProfileOpen(false)}
            />
        </>
    );
}